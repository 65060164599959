// Función para obtener datos desde el archivo PHP
async function fetchData() {
  try {
    const response = await fetch("getDataW.php"); // Realizar solicitud a getData.php
    const data = await response.json(); // Convertir la respuesta a JSON

    // Verificar si hay un error
    if (data.error) {
      document.querySelectorAll(".addressw").forEach((element) => {
        element.innerText = `Error: ${data.error}`;
      });
      return;
    }

    // Obtener los campos de los datos
    const fields = data.fields;

    if (fields.address && fields.address.stringValue) {
      // Actualizar los textos de las direcciones si existen
      document.querySelectorAll(".addressw").forEach((address) => {
        address.innerText = fields.address.stringValue; // Mostrar la dirección
      });
    }

    // Asignar el evento de clic al botón para abrir el enlace
    if (fields.linkLocation && fields.linkLocation.stringValue) {
      document.querySelectorAll(".address-linkw").forEach((button) => {
        button.addEventListener("click", function () {
          window.open(fields.linkLocation.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    // Actualizar los números de teléfono y agregar evento de clic
    if (fields.phoneNumber && fields.phoneNumber.stringValue) {
      const phoneNumber = fields.phoneNumber.stringValue; // Obtener el número de teléfono
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber); // Formatear el número de teléfono

      document.querySelectorAll(".phonew").forEach((phoneElement) => {
        phoneElement.textContent = formattedPhoneNumber; // Mostrar el número de teléfono formateado

        // Agregar evento de clic para abrir el enlace de llamada telefónica
        phoneElement.addEventListener("click", function () {
          window.location.href = "tel:" + phoneNumber; // Redirigir a un enlace de llamada
        });
      });
    }

    // Actualizar días y horarios dinámicamente desde la base de datos
    if (fields.hours && fields.hours.mapValue) {
      const hoursData = fields.hours.mapValue.fields;

      // Días de la semana en orden de lunes a domingo
      const daysOrder = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      // Obtener los horarios en el orden de los días
      const sortedDays = daysOrder.map((day) => ({
        day,
        hours: hoursData[day] ? hoursData[day].stringValue : "",
      }));

      // Agrupar los días con el mismo horario
      const groupedHours = [];
      let currentGroup = {
        days: [sortedDays[0].day],
        hours: sortedDays[0].hours,
      };

      for (let i = 1; i < sortedDays.length; i++) {
        if (sortedDays[i].hours === currentGroup.hours) {
          // Si el horario es el mismo, agrupar el día
          currentGroup.days.push(sortedDays[i].day);
        } else {
          // Si el horario cambia, guardar el grupo actual y empezar uno nuevo
          groupedHours.push(currentGroup);
          currentGroup = {
            days: [sortedDays[i].day],
            hours: sortedDays[i].hours,
          };
        }
      }
      // Agregar el último grupo
      groupedHours.push(currentGroup);

      // Obtener todas las listas <ul> con la clase "horarios-windsor"
      const horariosLists = document.querySelectorAll(".horarios-wellington");

      horariosLists.forEach((horariosList) => {
        horariosList.innerHTML = ""; // Limpiar contenido previo

        // Insertar los horarios agrupados en cada lista
        groupedHours.forEach((group) => {
          const dayRange =
            group.days.length > 1
              ? `${group.days[0].slice(0, 3)} - ${group.days[
                  group.days.length - 1
                ].slice(0, 3)}`
              : group.days[0].slice(0, 3);

          // Crear elementos <li> para los días y horas
          const dayItem = document.createElement("li");
          dayItem.classList.add("days");
          dayItem.textContent = dayRange;

          const hourItem = document.createElement("li");
          hourItem.classList.add("hours");
          hourItem.textContent = group.hours;

          // Agregar los elementos a la lista actual
          horariosList.appendChild(dayItem);
          horariosList.appendChild(hourItem);
        });
      });
    }

    //Actualizar las redes sociales y agregar evento de click
    if (fields.facebook && fields.facebook.stringValue) {
      document.querySelectorAll(".fbw").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.facebook.stringValue, "_blank");
        });
      });
    }

    if (fields.instagram && fields.instagram.stringValue) {
      document.querySelectorAll(".igw").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.instagram.stringValue, "_blank");
        });
      });
    }

    if (fields.yelp && fields.yelp.stringValue) {
      document.querySelectorAll(".ypw").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.yelp.stringValue, "_blank");
        });
      });
    }

    if (fields.tiktok && fields.tiktok.stringValue) {
      document.querySelectorAll(".tkw").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.tiktok.stringValue, "_blank");
        });
      });
    }

    //Actualizar las ordenes en linea y agregar evento de click
    if (fields.pick_up && fields.pick_up.stringValue) {
      document.querySelectorAll(".order-pickupw").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.pick_up.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    if (fields.delivery1 && fields.delivery1.stringValue) {
      document.querySelectorAll(".order-delivery1w").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.delivery1.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    if (fields.delivery2 && fields.delivery2.stringValue) {
      document.querySelectorAll(".order-delivery2w").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.delivery2.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    if (fields.delivery3 && fields.delivery3.stringValue) {
      document.querySelectorAll(".order-delivery3w").forEach((span) => {
        span.addEventListener("click", function () {
          window.open(fields.delivery3.stringValue, "_blank"); // Abrir el enlace en una nueva pestaña
        });
      });
    }

    // Actualizar el contenido del iframe
    if (
      fields.restaurantIframeGoogleLink &&
      fields.restaurantIframeGoogleLink.stringValue
    ) {
      document.querySelectorAll(".iframew").forEach((iframeContainer) => {
        iframeContainer.innerHTML =
          fields.restaurantIframeGoogleLink.stringValue; // Inserta el iframe como HTML
      });
    }
  } catch (error) {
    document.querySelectorAll(".addressw").forEach((element) => {
      element.innerText = `Error al obtener los datos: ${error.message}`;
    });
  }
}

// Función para formatear el número de teléfono en el formato (000) 000-0000
function formatPhoneNumber(phoneNumber) {
  // Eliminar cualquier carácter que no sea un dígito
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Extraer los diferentes componentes del número de teléfono
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  // Si coincide con el formato esperado, devolver el número formateado
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  // Si no coincide, devolver el número original sin formatear
  return phoneNumber;
}

// Ejecutar la función fetchData sin ninguna restricción de URL
fetchData();
